import React from "react";
import solutions from "./solutions.png";
import SustainabilityCommitment from "./Sustanibility";
import Partners from "./Partners";
import { useNavigate } from "react-router-dom";

const SectionThree = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/book-demo#section2");
  };

  return (
    <div style={{ height: "300vh" }}>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "50px",
          width: "100%",
          height: "25%",
          marginTop: "10%"
        }}
      >
        {/* Left Text Section */}
        <div style={{ flex: 1.25, paddingRight: "50px" }}>
          <h4
            style={{
              color: "#011627",
              fontSize: "24px",
              fontWeight: 200,
              margin: 0,
            }}
          >
            SOLUTIONS
          </h4>
          <h1
            style={{ color: "#011627", fontSize: "64px", fontWeight: "bold" }}
          >
            Empowering Sustainability
            <br />
            with Effortless Solutions.
          </h1>
          <p
            style={{
              color: "#011627",
              fontSize: "24px",
              fontWeight: 500,
              marginTop: "20px",
            }}
          >
            Customized plans to reduce environmental impact, promote diversity,
            and strengthen governance practices for a sustainable future.
          </p>
          <button
            style={{ zIndex: 20, position: "relative", fontWeight: 600 }}
            className="cta-button"
            onClick={handleClick}
          >
            Book A Demo
          </button>
        </div>

        {/* Right Image Section */}
        <div style={{ flex: 0.75, display: "flex", justifyContent: "center" }}>
          {/* Placeholder for the image */}
          <div
            style={{
              width: "100%",
              height: "auto",

              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              borderRadius: "8px",
            }}
          >
            {/* Insert the image here */}
            <img
              src={solutions}
              alt="Sustainability Dashboard"
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          </div>
        </div>
      </section>
      <SustainabilityCommitment />
      <Partners />
    </div>
  );
};

export default SectionThree;
