import React, { useEffect } from "react";
import ScrollSpyComponent from "./Components/ScrollSpyComponent";

const DemoBook = () => {
  useEffect(() => {
    // Check for hash in the URL
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" }); // Scroll to the element
        }
      }, 100); // Small delay to ensure rendering is complete
    }
  }, []); // Runs on component mount

  return (
    <div>
      <ScrollSpyComponent />
    </div>
  );
};

export default DemoBook;
