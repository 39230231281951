import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as THREE from "three";
import { TextureLoader } from "three";
import lightBulb from "../Resources/bulb.png";
import "./sectionone.css";
import ParticleBackground from "../Resources/ParticleBackground";

const SectionOne = () => {
  const [texture, setTexture] = useState();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(
      lightBulb,
      (loadedTexture) => {
        console.log('Texture loaded successfully', loadedTexture); // Log loaded texture
        setTexture(loadedTexture);
      },
      undefined,
      (error) => {
        console.error('Error loading texture:', error); // Log any errors
      }
    );
  }, []);

  const handleMouseMove = (event) => {
    const { clientX, clientY, innerWidth, innerHeight } = event;
    setMouse({
      x: (clientX / innerWidth) * 2 - 1,
      y: -(clientY / innerHeight) * 2 + 1,
    });
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/book-demo#section2");
  };

  return (
    <div
      className="section-one"
      style={{ height: "86vh", paddingTop: "0px" }}
      onMouseMove={handleMouseMove}
    >
      <ParticleBackground />
      <div className="content">

        <h1>Revolutionize Your Sustainability Journey with RIU</h1>
        <p>
          From Data to Action in Hours. Transform ESG performance across supply
          chains effortlessly. Explore the future of sustainability with us.
        </p>
        <button style={{ zIndex: 20, position: "relative" }} className="cta-button" onClick={handleClick}>Book A Demo</button>
      </div>

      <div className="image-container">
        {texture && (
          <>
            <img src={lightBulb} />

          </>

        )}
      </div>
    </div>
  );
};

export default SectionOne;
