import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import group from "../../../../Components/SectionTwo/group.png";
import business from "../../../ESGDataManagement/Components/SectionTwo/businessgroup.png"
import man from "./man.png"

const SectionTwo = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/book-demo#section2");
  };
  const containerStyle = {
    backgroundColor: "#F8F9FA",
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const cardsWrapperStyle = {
    display: "flex",
    marginTop: "5vh",
    justifyContent: "space-between",

    gap: "20px",
    width: "80%",
  };

  const cardStyle = {
    border: "3px solid #9AC1D0", // Blue border color
    backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
    width: "30%",
    padding: "30px",
    borderRadius: "20px",
    textAlign: "left",
  };

  const titleStyle = {
    fontSize: "32px",
    display: "flex",
    flexWrap: "wrap",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    display: "flex",
    flexWrap: "wrap",
    color: "#555",
  };

  return (
    <>
      <Row className="" style={{ width: "100%", padding: "0px 4rem", marginTop:"4rem", marginBottom:"4rem" }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          Carbon Accounting Platform
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          Simplify your carbon footprint with accurate GHG Scope 1, 2 & 3 emission reporting calculated with our advanced carbon accounting software & precise emission factors
          </div>
          <Button
            onClick={handleClick}
            style={{
              color: "white",
              background: "#3F88A5",
              fontSize: "16px",
              padding: "15px 30px",
              width: "15vw",
              fontWeight: 600,
              border: "none",
            }}
            className="mt-3"
          >
            BOOK A DEMO
          </Button>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={group} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <div style={containerStyle}>
        <div style={{ fontSize: "32px", fontWeight: 700 }}>
        A comprehensive strategy for effectively handling your company's carbon footprint
        </div>
        <div style={cardsWrapperStyle}>
          <div style={cardStyle}>
            <h3 style={titleStyle}>
            Calculate Carbon Footprint
            </h3>
            <p style={paragraphStyle}>
            Efficiently assess your company's carbon footprint, integrating emissions from Scope 1 (direct), Scope 2 (indirect), and Scope 3 (value chain), across all operational facets.
            </p>
          </div>
          <div style={cardStyle}>
            <h3 style={titleStyle}>GHG Reporting and Audit</h3>
            <p style={paragraphStyle}>
            Export emissions data that is ready for audit, for reporting to investors, supply chain, regulators, stakeholders, and various ESG frameworks such as GRI, BRSR, TCFD, CSRD etc.
            </p>
          </div>
          <div style={cardStyle}>
            <h3 style={titleStyle}>Decarbonization & Net-Zero</h3>
            <p style={paragraphStyle}>
            Visualize your footprint by Scope 1, 2 & 3 to identify high emissions operational areas and set ESG targets based on forward-looking strategies for emissions reduction.
            </p>
          </div>
        </div>
      </div>
      <Row className="" style={{ width: "100%", padding: "0px 4rem",marginBottom:"1rem"  }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "48px", fontWeight: 600 }}>
          Calculate your business emissions
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          Streamline environmental, social, and governance (ESG) reporting and carbon accounting.
          </div>

          <div style={{ fontSize: "38px", fontWeight: 500, marginTop:"20px" }}>
          Comprehensive data analysis
          </div>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          Automatically calculate scope 1, 2, and 3 emissions with 300+ custom developed emission factors that represent 225 countries and territories.
          </div>
          <div style={{ fontSize: "38px", fontWeight: 500, marginTop:"10px" }}>

          For all your locations
          </div>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          Track carbon emissions and operational data across multiple facilities, franchises, and locations.
          </div>
          <div style={{ fontSize: "38px", fontWeight: 500, marginTop:"10px" }}>

          Every emissions category​
          </div>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          Get a complete picture of environmental data, including energy, waste, buildings, vehicles, product footprint, and more, so you can stay ahead of upcoming regulations.
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={man} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <Row className="" style={{ width: "100%", padding: "3rem 4rem", backgroundColor: "#F8F9FA",     }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          Scale seamlessly 
          </h2>
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          as your business grows
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          RIU allows them to support their  organization in this rapid expansion without additional costs and allowed them to  maintain simplicity in an increasingly complex environment.
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={business} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SectionTwo;
