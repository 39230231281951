import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import group from "./group.png";
import business from "../../../ESGDataManagement/Components/SectionTwo/businessgroup.png"
import t from "./t.png"
import earthbulb from "./earthbulb.png"

const SectionTwo = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/book-demo#section2");
  };
  const containerStyle = {
    backgroundColor: "#F8F9FA",
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const cardsWrapperStyle = {
    display: "flex",
    marginTop: "5vh",
    justifyContent: "space-between",

    gap: "20px",
    width: "80%",
  };

  const cardStyle = {
    border: "3px solid #9AC1D0", // Blue border color
    backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
    width: "30%",
    padding: "30px",
    borderRadius: "20px",
    textAlign: "left",
  };

  const titleStyle = {
    fontSize: "32px",
    display: "flex",
    flexWrap: "wrap",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    display: "flex",
    flexWrap: "wrap",
    color: "#555",
  };

  return (
    <>
      <Row className="" style={{ width: "100%", padding: "0px 4rem", marginTop:"4rem", marginBottom:"4rem" }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          SUSTAINABILITY & ESG REPORTING

          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          ESG reports aligned with internationally recognized sustainability frameworks such as GRI, CSRD, ESRS, TCFD, CDP, BRSR and others using the automated data fetch feature.
          </div>
          <Button
            onClick={handleClick}
            style={{
              color: "white",
              background: "#3F88A5",
              fontSize: "16px",
              padding: "15px 30px",
              width: "15vw",
              fontWeight: 600,
              border: "none",
            }}
            className="mt-3"
          >
            BOOK A DEMO
          </Button>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={group} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <div style={containerStyle}>
     
      <Row className="" style={{ width: "100%", padding: "0px 4rem",marginBottom:"1rem"  }}>
      <Col
          md={6}
          className="d-flex align-items-start justify-content-start"
        >
          <img
            src={t} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "32px", fontWeight: 700, marginBottom:"25px", marginTop:"25px" }}>
          Global Sustainability Reporting     
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500, marginBottom:"50px" }}>
          Report comparable and credible metrics and disclosures using an efficient reporting system with globally adopted up-to-date sustainability standards such as GRI, TCFD, CSRD, ESRS, BRSR CDP, etc.
          </div>
          <h2 className="" style={{ fontSize: "32px", fontWeight: 700, marginBottom:"25px",marginTop:"25px" }}>
          Comply with SEBI's Sustainability Reporting
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500, marginBottom:"50px" }}>
          Heighten long-term performance and value creation prospects with BRSR reporting with an automated data- fetch feature that enables multi-year data reporting.
          </div>
          <h2 className="" style={{ fontSize: "32px", fontWeight: 700, marginBottom:"25px",marginTop:"25px" }}>
          Custom ESG Reports for your Business
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500, marginBottom:"50px" }}>
          Get customized reports specific to location and ESG indicators, generate ESG reports on the supply chain, and provide your company's stakeholders with more timely and accurate ESG information.
          </div>

       
       
        </Col>
        
      </Row>
      
      </div>
      <Row className="" style={{ width: "100%", padding: "0px 4rem",marginBottom:"1rem"  }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "48px", fontWeight: 600 }}>
          Measure your ESG Performance
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          Get a detailed, intuitive suite of ESG analyses custom-made for your industry, including the UN SDGs, Gap Analysis, and ESG Mapping. Using the self-assessment feature, generate a Transparency Score against global ESG standards to see where you stand in terms of ESG performance.
          </div>

       
       
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={earthbulb} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <Row className="" style={{ width: "100%", padding: "3rem 4rem", backgroundColor: "#F8F9FA",     }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          Scale seamlessly 
          </h2>
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          as your business grows
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          RIU allows them to support their  organization in this rapid expansion without additional costs and allowed them to  maintain simplicity in an increasingly complex environment.
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={business} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SectionTwo;
