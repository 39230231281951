import React, { useEffect, useRef } from "react";
import world from './world.png'
import "./SectionThree.css"

const SectionThree = () => {
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    container.classList.add("scrolling");

    return () => {
        container.classList.remove("scrolling"); // Clean up on unmount
    };
}, [containerRef]);

  const DecarbonizationCard = () => {
   
    return (
      <div style={styles.cardContainer}>
        <div style={styles.iconContainer}>
          <div style={styles.circle}>
            <span style={styles.arrowIcon}>→</span>
          </div>
        </div>
        <div style={styles.contentContainer}>
          <h2 style={styles.title}>Decarbonization</h2>
          <h3 style={styles.subtitle}>backed by science</h3>
          <p style={styles.description}>
            Our science-first approach ensures your sustainability strategy meets international standards and protocol. With a certified methodology that enables businesses to transform to climate pioneers, you achieve real results, fast.
          </p>
        </div>
      </div>
    );
  };

  const DecarbonizationCardTwo = () => {
    return (
      <div style={styles.cardContainer}>
        <div style={styles.iconContainer}>
          <div style={styles.circle}>
            <span style={styles.arrowIcon}>→</span>
          </div>
        </div>
        <div style={styles.contentContainer}>
          <h2 style={styles.title}>Save time</h2>
          <h3 style={styles.subtitle}>and focus on what matters most</h3>
          <p style={styles.description}>
          Leave it to us to crunch the numbers and guide you on your sustainability journey. With our awardwinning software and expert support, you can easily integrate sustainability into your business strategy,  leaving you time to focus on your core business.
          </p>
        </div>
      </div>
    );
  };
  
  const styles = {
    cardContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: '#F5F9FA', // Light background color
  
      borderRadius: '8px',
    
      fontFamily: 'Arial, sans-serif',
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '-4.5%',
      marginTop:"5%"
    },
    circle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#3F88A5', // Light blue background for the circle
      color: '#5A9CAA', // Blue color for the arrow icon
      fontSize: '24px',
      fontWeight: 'bold',
      height: '70px',
      width: '70px',
      borderRadius: '50%',
      position: 'relative',
      top: '-10px', // Adjust vertical position to center on the line
    },
    arrowIcon: {
      color: '#FFFFFF', // White arrow color inside the circle
      marginTop:"-3%",
      fontSize:"40px"
    },
    contentContainer: {
      borderLeft: '2px solid #3F88A5', // Light vertical line
      paddingLeft: '6%',
      paddingTop:"4%"

    },
    title: {
      color: '#3F88A5', // Blue color for title text
      fontSize: '58px',
      fontWeight: 'bold',
      margin: 0,
    },
    subtitle: {
      color: '#000', // Dark color for subtitle
      fontSize: '58px',
      fontWeight: 'bold',
      marginTop: '5px',
      marginBottom: '15px',
    },
    description: {
      color: '#333', // Darker color for description text
      fontSize: '24px',
      lineHeight: '2',
    },
  };

  return (
    <div style={{ width: "100%", background: "#f4f8f9", paddingLeft:"3rem",paddingRight:"3rem",paddingTop:"2rem" }}>
      {/* <div className="w-100" style={{ textAlign: "center" , display: "none"}}>
        <h1
          style={{
            fontFamily: "'Open Sans', sans-serif", // Specify Open Sans font
            fontSize: "64px", // Set font size to 24px
            color: "#011627",
            fontWeight: "bold",
          }}
        >
          Elevate Your Sustainability
        </h1>
        <h1
          style={{
            fontFamily: "'Open Sans', sans-serif", // Specify Open Sans font
            fontSize: "64px", // Set font size to 24px
            color: "#011627",
            fontWeight: "bold",
          }}
        >
          Journey with RIU
        </h1>
      </div> */}
      <div style={{width:"100%", display:'flex', overflow:"hidden", marginTop: "5%"}}>
        <div style={{width:"50%", textAlign:"center"}}>
          <img src={world}/>

        </div>
        <div className="cards-containerr" style={{width:"50%",   overflowY: 'auto', paddingTop:"40px", marginTop:"40vh"}}      ref={containerRef}>
          <DecarbonizationCard/>
          <DecarbonizationCardTwo/>
            
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
