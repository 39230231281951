import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SectionOne from "./SectionOne/SectionOne";
import Scrollspy from "react-scrollspy";
import SectionTwo from "./SectionTwo/SectionTwo";
import SectionThree from "./SectionThree/SectionThree";

const ScrollSpyComponent = () => {
  const [activeSection, setActiveSection] = useState("section1");

  useEffect(() => {
    const sections = document.querySelectorAll(".section");

    const handleScroll = () => {
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      console.log(section);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="d-flex" >
      <nav className="scrollspy-nav" style={{zIndex:10}}>
        <Scrollspy
          items={["section1", "section2", "section3", "section4"]}
          currentClassName="active"
        >
          <li>
            <div className="outer-circle">
              <a href="#section1" className="inner-circle"></a>
            </div>
          </li>
          <li>
            <div className="outer-circle">
              <a href="#section2" className="inner-circle"></a>
            </div>
          </li>
          <li>
            <div className="outer-circle">
              <a href="#section3" className="inner-circle"></a>
            </div>
          </li>
         
        </Scrollspy>
      </nav>

      <div style={{ paddingTop: "10px", width:"100%" }}>
        <div id="section1">
          <SectionOne />
        </div>
        <div id="section2">
          <SectionTwo />
        </div>
        <div id="section3">
          <SectionThree />
        </div>
      
      </div>
    </div>
  );
};

export default ScrollSpyComponent;
