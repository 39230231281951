import React, { useRef, useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { TextureLoader } from "three";
import Footer from "../../../Home/Components/SectionFour/Footer/Footer";
import lets from "../../../Home/Components/SectionFour/lets.png";
import { useNavigate } from "react-router-dom";

function getImageData(image, width, height) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;
  context.drawImage(image, 0, 0, width, height);
  return context.getImageData(0, 0, width, height).data;
}

// Particle system with instancing and input interaction
const InstancedParticles = ({ texture, mouse }) => {
  const meshRef = useRef();
  const [originalPositions, setOriginalPositions] = useState(null);
  const numParticles = 500;
  const imageWidth = texture.image.width;
  const imageHeight = texture.image.height;

  useEffect(() => {
    const positions = new Float32Array(numParticles * 3);
    const instanceColors = new Float32Array(numParticles * 3);

    const imageData = getImageData(texture.image, imageWidth, imageHeight);
    let index = 0;
    for (let y = 0; y < imageHeight; y++) {
      for (let x = 0; x < imageWidth; x++) {
        const pixelIndex = (y * imageWidth + x) * 4;
        const r = imageData[pixelIndex] / 255;
        const g = imageData[pixelIndex + 1] / 255;
        const b = imageData[pixelIndex + 2] / 255;
        const a = imageData[pixelIndex + 3] / 255;

        if (a > 0.5 && index < numParticles) {
          positions[index * 3] = (x / imageWidth - 0.5) * 2; // X position
          positions[index * 3 + 1] = -(y / imageHeight - 0.5) * 2; // Y position
          positions[index * 3 + 2] = 0; // Z position

          instanceColors[index * 3] = r;
          instanceColors[index * 3 + 1] = g;
          instanceColors[index * 3 + 2] = b;

          index++;
        }
      }
    }

    setOriginalPositions(positions.slice());

    const geometry = new THREE.InstancedBufferGeometry();
    geometry.setAttribute(
      "instancePosition",
      new THREE.InstancedBufferAttribute(positions, 3)
    );
    geometry.setAttribute(
      "instanceColor",
      new THREE.InstancedBufferAttribute(instanceColors, 3)
    );
    meshRef.current.geometry = geometry; // Assign the geometry to the mesh
  }, [texture]);

  useFrame(() => {
    // Ensure meshRef.current and its attributes are defined
    if (
      meshRef.current &&
      meshRef.current.geometry &&
      meshRef.current.geometry.attributes.instancePosition &&
      originalPositions // Check if originalPositions is not null
    ) {
      const positions =
        meshRef.current.geometry.attributes.instancePosition.array;

      // Simple interaction: move particles based on mouse input
      for (let i = 0; i < positions.length; i += 3) {
        const dx = mouse.x - positions[i];
        const dy = mouse.y - positions[i + 1];
        const dist = Math.sqrt(dx * dx + dy * dy);

        if (dist < 0.3) {
          positions[i] += dx * 0.01;
          positions[i + 1] += dy * 0.01;
        } else {
          positions[i] += (originalPositions[i] - positions[i]) * 0.05;
          positions[i + 1] +=
            (originalPositions[i + 1] - positions[i + 1]) * 0.05;
        }
      }

      // Mark the attribute as needing an update
      meshRef.current.geometry.attributes.instancePosition.needsUpdate = true;
    }
  });

  return (
    <instancedMesh ref={meshRef} args={[null, null, numParticles]}>
      <sphereGeometry args={[0.05, 16, 16]} />
      <meshBasicMaterial vertexColors depthWrite={false} />
    </instancedMesh>
  );
};

const SectionFour = () => {
  const [texture, setTexture] = useState();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(
      lets,
      (loadedTexture) => {
        console.log("Texture loaded successfully", loadedTexture); // Log loaded texture
        setTexture(loadedTexture);
      },
      undefined,
      (error) => {
        console.error("Error loading texture:", error); // Log any errors
      }
    );
  }, []);

  const handleMouseMove = (event) => {
    const { clientX, clientY, innerWidth, innerHeight } = event;
    setMouse({
      x: (clientX / innerWidth) * 2 - 1,
      y: -(clientY / innerHeight) * 2 + 1,
    });
  };

  const handleClick = () => {
    const section = document.getElementById("section2");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const navigate = useNavigate();
  const solutionsPage = () => {
    navigate("/solutions");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: 700,
            fontSize: "40px",
            marginBottom: "2%",
            marginTop: "2%",
          }}
        >
          WHAT WE DO
        </div>
        <div
          style={{
            display: "flex",
            paddingLeft: "4rem",

            paddingRight: "4rem",
            borderBottom: "1px solid black",
            paddingBottom: "4rem",
            padding: "3.5rem",
            height: "40%",
            gap: "1rem",
          }}
        >
          <div
            style={{
              backgroundColor: "#D9E7ED",
              padding: "2.2rem",
              borderRadius: "8px",
              flex: 1,
            }}
          >
            <h3
              style={{
                fontSize: "32px",
                color: "#1a1a1a",
                fontWeight: 600,
                marginBottom: "1rem",
              }}
            >
              Solutions
            </h3>
            <p
              style={{
                color: "#011627",
                fontSize: "18px",
                lineHeight: "1.5",
                fontWeight: 500,
              }}
            >
              ESG and non-financial reporting is creating a lot of buzz today
              and the ever-evolving regulatory norms and stakeholder
              expectations are proving to be intimidating to business owners.
              Companies are finding it hard to gather data........
            </p>
            <button
              style={{
                background: "#3f88a5",
                borderRadius: "10px",
                border: "none",
                padding: "10px 20px",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={solutionsPage}
            >
              Discover More
            </button>
          </div>

          <div
            style={{
              backgroundColor: "#D9E7ED",
              padding: "2.2rem",
              borderRadius: "8px",
              flex: 1,
            }}
          >
            <h3
              style={{
                fontSize: "32px",
                color: "#1a1a1a",
                fontWeight: 600,
                marginBottom: "1rem",
              }}
            >
              Sustainable Frameworks
            </h3>
            <p
              style={{
                color: "#011627",
                fontSize: "18px",
                lineHeight: "1.5",
                fontWeight: 500,
              }}
            >
              Through the Carbon Footprint Strategy offering, we aid you in
              calculating, reporting, and improving your carbon footprint. The
              entire disclosure cycle would be based to the carbon footprint
              exposure metrics as prescribed by the Task-Force ...........{" "}
            </p>
            <button
              style={{
                background: "#3f88a5",
                borderRadius: "10px",
                border: "none",
                padding: "10px 20px",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={solutionsPage}
            >
              Discover More
            </button>{" "}
          </div>
        </div>
      </div>
      <div
        className="section-one"
        style={{ height: "23%", paddingTop: "2%", width: "100%" }}
        onMouseMove={handleMouseMove}
      >
        <div className="content">
          <div
            style={{
              color: "#011627",
              fontSize: "24px",
              display: "flex",
              gap: "1em",
            }}
          >
            <span>G E T</span>
            <span>I N</span>
            <span>T O U C H</span>
          </div>

          <h1 style={{ fontSize: "64px" }}>Let's Create Change </h1>
          <h1 style={{ fontSize: "64px" }}>Together. Reach Out Today</h1>

          <p style={{ fontSize: "24px", fontWeight: 500, marginBottom: "2px" }}>
            Connect with us to spark sustainable action. Let's make a
          </p>
          <p style={{ fontSize: "24px", fontWeight: 500 }}>
            difference together.
          </p>
          <button
            style={{ zIndex: 20, position: "relative", fontWeight: 600 }}
            className="cta-button"
            onClick={handleClick}
          >
            Schedule a call
          </button>
        </div>

        <div
          className="image-container"
          style={{
            height: "100%",
            marginLeft: "0%",
            marginTop: "0%",
            width: "30%",
          }}
        >
          {texture && (
            <>
              <img
                src={lets}
                style={{
                  height: "100%",
                  width: "170%",
                  marginLeft: "-125%",
                  maxWidth: "200%",
                }}
              />
            </>
          )}
        </div>
      </div>
      <div style={{ height: "18%", width: "100%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default SectionFour;
