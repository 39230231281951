import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import group from "./group.png"
import grouptwo from "./group2.png"
import groupthree from "./groupthree.png"

import { useNavigate } from 'react-router-dom';

const SectionTwo = () => {
  const [activeSection, setActiveSection] = useState('esgDataManagement');

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/solutions/esg-data-management');
  };
  const handleClickTwo = () => {
    navigate('/solutions/carbon-accounting');
  };
  const handleClickThree = () => {
    navigate('/solutions/sustainabilityandesg');
  };
  const handleClickFour = () => {
    navigate('/solutions/esganalytics');
  };
  const handleClickFive = () => {
    navigate('/solutions/supplierassesment');
  };

  // Function to render content based on the active section
  const generateUI = () => {
    switch (activeSection) {
      case 'esgDataManagement':
        return (
          <Row className="mt-5">
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h2 className="" style={{fontSize:"64px", fontWeight:600}}>ESG Data Management</h2>
              <div style={{fontSize:"24px", fontWeight:500}}>
                Track, analyze, and consolidate your ESG data using a centralized data collection
                and management system for better decision-driven analytics and value creation.
              </div>
              <Button onClick={handleClick} style={{color:"white", background:"#3F88A5", fontSize:"16px", padding:"15px 30px", width:"15vw",fontWeight:600, border:"none"}} className="mt-3">
                DISCOVER MORE
              </Button>
            </Col>
            <Col md={6} className="d-flex align-items-center justify-content-center">
              <img
                src={group} // replace with actual image path
                alt="ESG Data Visualization"
                className="img-fluid"
                style={{marginTop:"30px"}}
              />
            </Col>
          </Row>
        );
      case 'carbonAccounting':
        return (
          <Row className="mt-5">
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h2 className="" style={{fontSize:"64px", fontWeight:600}}>Carbon Accounting Platform</h2>
            <div style={{fontSize:"24px", fontWeight:500}}>
            Simplify your carbon footprint with accurate GHG Scope 1, 2 & 3 emission reporting calculated with our advanced carbon accounting software & precise emission factors
            </div>
            <Button onClick={handleClickTwo} style={{color:"white", background:"#3F88A5", fontSize:"16px", padding:"15px 30px", width:"15vw",fontWeight:600, border:"none"}} className="mt-3">
              DISCOVER MORE
            </Button>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center">
            <img
              src={grouptwo} // replace with actual image path
              alt="ESG Data Visualization"
              className="img-fluid"
              style={{marginTop:"30px"}}
            />
          </Col>
        </Row>
        );
      // Add more cases for additional sections
      case 'sustainabilityReporting':

        return (
          <Row className="mt-5">
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h2 className="" style={{fontSize:"64px", fontWeight:600}}>SUSTAINABILITY & ESG REPORTING
            </h2>
            <div style={{fontSize:"24px", fontWeight:500}}>
            ESG reports aligned with internationally recognized sustainability frameworks such as GRI, CSRD, ESRS, TCFD, CDP, BRSR and others using the automated data fetch feature.
            </div>
            <Button onClick={handleClickThree} style={{color:"white", background:"#3F88A5", fontSize:"16px", padding:"15px 30px", width:"15vw",fontWeight:600, border:"none"}} className="mt-3">
              DISCOVER MORE
            </Button>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center">
            <img
              src={groupthree} // replace with actual image path
              alt="ESG Data Visualization"
              className="img-fluid"
              style={{marginTop:"30px"}}
            />
          </Col>
        </Row>
        );

        case 'esgAnalytics':
        
        return (
          <Row className="mt-5">
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h2 className="" style={{fontSize:"64px", fontWeight:600}}>ESG Analytics
            </h2>
            <div style={{fontSize:"24px", fontWeight:500}}>
            Make strategic decisions, benchmark performance, and communicate more effectively with internal and external stakeholders with robust ESG analysis.
            </div>
            <Button onClick={handleClickFour} style={{color:"white", background:"#3F88A5", fontSize:"16px", padding:"15px 30px", width:"15vw",fontWeight:600, border:"none"}} className="mt-3">
              DISCOVER MORE
            </Button>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center">
            <img
              src={groupthree} // replace with actual image path
              alt="ESG Data Visualization"
              className="img-fluid"
              style={{marginTop:"30px"}}
            />
          </Col>
        </Row>
        );

        case 'supplierAssesment':
        
        return (
          <Row className="mt-5">
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h2 className="" style={{fontSize:"64px", fontWeight:600}}>  Smarter way to manage risky suppliers
            </h2>
            <div style={{fontSize:"24px", fontWeight:500}}>
            Discover how easily you can manage your suppliers, assess supplier risks using custom assessments,  evaluate risks, and increase visibility into suppliers ESG practices.
            </div>
            <Button onClick={handleClickFive} style={{color:"white", background:"#3F88A5", fontSize:"16px", padding:"15px 30px", width:"15vw",fontWeight:600, border:"none"}} className="mt-3">
              DISCOVER MORE
            </Button>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center">
            <img
              src={groupthree} // replace with actual image path
              alt="ESG Data Visualization"
              className="img-fluid"
              style={{marginTop:"30px"}}
            />
          </Col>
        </Row>
        );
      default:
        return null;

        

    }
  };

  return (
    <Container fluid className="p-5">
      {/* Button Navigation Section */}
      <div className="d-flex justify-content-between mb-4" style={{marginTop:"5%"}}>
        <Button
          onClick={() => setActiveSection('esgDataManagement')}
          style={{
            fontSize: "20px",
            border: "none",
            borderRadius:"0px",
            background:"white",
            color:"black", fontWeight:500,letterSpacing:"0.1rem",
            borderBottom: activeSection === 'esgDataManagement' ? "4px solid #3f88a5" : "none",
          }}
          className="mx-2"
        >
          ESG Data Management
        </Button>
        <Button
         style={{
            fontSize: "20px",
            border: "none",
            borderRadius:"0px",
            background:"white",
            color:"black", fontWeight:500,letterSpacing:"0.1rem",
            borderBottom: activeSection === 'carbonAccounting' ? "4px solid #3f88a5" : "none",
          }}
          onClick={() => setActiveSection('carbonAccounting')}
          className="mx-2"
        >
          Carbon Accounting
        </Button>
        <Button
           style={{
            fontSize: "20px",
            border: "none",
            borderRadius:"0px",
            background:"white",
            color:"black", fontWeight:500,letterSpacing:"0.1rem",
            borderBottom: activeSection === 'sustainabilityReporting' ? "4px solid #3f88a5" : "none",
          }}
          onClick={() => setActiveSection('sustainabilityReporting')}
          className="mx-2"
        >
          Sustainability & ESG Reporting
        </Button>
        <Button
          style={{
            fontSize: "20px",
            border: "none",
            borderRadius:"0px",
            background:"white",
            color:"black", fontWeight:500,letterSpacing:"0.1rem",
            borderBottom: activeSection === 'esgAnalytics' ? "4px solid #3f88a5" : "none",
          }}
          onClick={() => setActiveSection('esgAnalytics')}
          className="mx-2"
        >
          ESG Analytics
        </Button>
        <Button
          style={{
            fontSize: "20px",
            border: "none",
            borderRadius:"0px",
            background:"white",
            color:"black", fontWeight:500,letterSpacing:"0.1rem",
            borderBottom: activeSection === 'supplierAssesment' ? "4px solid #3f88a5" : "none",
          }}
          onClick={() => setActiveSection('supplierAssesment')}
          className="mx-2"
        >
          Supplier Assesment
        </Button>
        {/* Add more buttons as needed */}
      </div>

      {/* Dynamic Content Section */}
      {generateUI()}
    </Container>
  );
};

export default SectionTwo;
