import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import group from "./group.png";
import business from "../../../ESGDataManagement/Components/SectionTwo/businessgroup.png";
import earth from "./earth.png";
import earthbulb from "./earthbulb.png";
import peer from "./peer.png";
const SectionTwo = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/book-demo#section2");
  };
  const containerStyle = {
    backgroundColor: "#F8F9FA",
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const cardsWrapperStyle = {
    display: "flex",
    marginTop: "5vh",
    justifyContent: "space-between",

    gap: "20px",
    width: "80%",
  };

  const cardStyle = {
    border: "3px solid #9AC1D0", // Blue border color
    backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
    width: "30%",
    padding: "30px",
    borderRadius: "20px",
    textAlign: "left",
  };

  const titleStyle = {
    fontSize: "32px",
    display: "flex",
    flexWrap: "wrap",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    display: "flex",
    flexWrap: "wrap",
    color: "#555",
  };

  return (
    <>
      <Row
        className=""
        style={{
          width: "100%",
          padding: "0px 4rem",
          marginTop: "4rem",
          marginBottom: "4rem",
        }}
      >
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
            Smarter way to manage risky suppliers
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
            Discover how easily you can manage your suppliers, assess supplier
            risks using custom assessments, evaluate risks, and increase
            visibility into suppliers ESG practices.
          </div>
          <Button
            onClick={handleClick}
            style={{
              color: "white",
              background: "#3F88A5",
              fontSize: "16px",
              padding: "15px 30px",
              width: "15vw",
              fontWeight: 600,
              border: "none",
            }}
            className="mt-3"
          >
            BOOK A DEMO
          </Button>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={group} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <div style={containerStyle}>
        <Row
          className=""
          style={{ width: "100%", padding: "0px 4rem", marginBottom: "1rem" }}
        >
          <Col
            md={6}
            className="d-flex align-items-start justify-content-start"
          >
            <img
              src={earth} // replace with actual image path
              alt="ESG Data Visualization"
              className="img-fluid"
              style={{ marginTop: "30px" }}
            />
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h2
              className=""
              style={{
                fontSize: "32px",
                fontWeight: 700,
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              Confidently identify and manage supplier performance
            </h2>
            <div
              style={{
                fontSize: "24px",
                fontWeight: 500,
                marginBottom: "50px",
              }}
            >
              Focus on leading data-based indicators by using dashboards
              integrated with configurable graphs, KPIs, and comprehensive
              filter.
            </div>
            <h2
              className=""
              style={{
                fontSize: "32px",
                fontWeight: 700,
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              Effortlessly measure supplier risks
            </h2>
            <div
              style={{
                fontSize: "24px",
                fontWeight: 500,
                marginBottom: "50px",
              }}
            >
              Calculate the risk level of your suppliers by evaluating their
              response to various ESG factors and determine accurate risk scores
              for each supplier.
            </div>
            <h2
              className=""
              style={{
                fontSize: "32px",
                fontWeight: 700,
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              Create Custom assessments with ease
            </h2>
            <div
              style={{
                fontSize: "24px",
                fontWeight: 500,
                marginBottom: "50px",
              }}
            >
              Create assessment questionnaires customized to your company's
              needs and implement them quickly to better understand the ESG
              risks associated with a supplier.
            </div>
          </Col>
        </Row>
      </div>
      <Row
        className=""
        style={{ width: "100%", padding: "0px 4rem", marginBottom: "1rem" }}
      >
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "48px", fontWeight: 600 }}>
            Enhanced visibility through real-time dashboards
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
            Get a full picture of your suppliers risks and opportunities through
            real-time dashboards and reports so that you can make better
            strategic decisions faster and spot trends and identify suppliers
            that need the most action.
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={peer} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <Row
        className=""
        style={{
          width: "100%",
          padding: "3rem 4rem",
          backgroundColor: "#F8F9FA",
        }}
      >
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
            Scale seamlessly
          </h2>
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
            as your business grows
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
            RIU allows them to support their organization in this rapid
            expansion without additional costs and allowed them to maintain
            simplicity in an increasingly complex environment.
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={business} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SectionTwo;
