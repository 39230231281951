
import "./SectionTwo.css";
import React, { useEffect, useRef } from 'react';
import measure from "./Resources/measure.svg"
import analyze from "./Resources/analyze.svg"
import benchmark from "./Resources/benchmark.svg"
import target from "./Resources/target.svg"
import report from "./Resources/report.svg"


const SectionTwo = () => {
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    container.classList.add("scrolling");

    return () => {
        container.classList.remove("scrolling"); // Clean up on unmount
    };
}, [containerRef]);

  const cardData = [
    {
      icon: measure,
      title: "MEASURE",
      description:
        "Set up your organization structure, control access by assigning roles and collect data from multiple stakeholders seamlessly",
    },
    {
      icon: analyze,
      title: "ANALYZE",
      description:
        "Analyze emissions by facilities, products, and departments to identify root causes through drill-down analysis and develop strategies for improvement of ESG metrics",
    },
    {
      icon: report,
      title: "REPORT",
      description:
        "Generate 100% audit ready standardized reports like BRSR, TCFD, GRI, IR, ensuring consistency and accuracy data reporting",
    },
    {
      icon: benchmark,
      title: "BENCHMARK",
      description:
        "Benchmark across peers and industries and compare ESG metrics against peers for targeted improvements and gain insights to improve your ESG metrics",
    },
    {
      icon: target,
      title: "SET TARGET",
      description:
        "Set Science-based targets aligned with frameworks like SBTi and Net Zero objectives and develop a roadmap with the help of our climate experts",
    },
  ];

  

  return (
    <div  style={{display:"flex",alignItems:"center",justifyContent:"center",paddingTop:"20px", height:"100vh"}}>
      <div
        style={{
          width: "70%",
          display: "flex",
          flexDirection: "column",
          alignItems: "cemter",
          justifyContent:"center",
          height: "100%",
        }}
      >
        <section className="hero-section">
          <h1>Elevate Your ESG Performance With RIU's All-In-One Platform</h1>
          <p>
            Unlock the power of sustainability with RIU’s user-friendly SaaS
            solution. Seamlessly manage, analyze, and report your ESG data with
            ease.
          </p>
        </section>

       
        <section className="cards-section">
          <div className="cards-container"
           ref={containerRef}
           style={{
          
             overflowY: 'auto',
           }}
           >
            {cardData.map((card, index) => (
              <div className="card" key={index} style={{paddingTop:"50px", paddingBottom:"50px"}}>
                <div className="card-icon"><img style={{height:"80px", width:"80px"}} src={card.icon}/></div>
                <div className="card-content">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{fontSize:"36px", fontWeight:500 }}>{card.title}</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexWrap: "wrap",
                          fontSize: "25px",

                          color: "#6c757d",
                          fontWeight: 300,
                          margin: 0,
                        }}
                      >
                        {card.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SectionTwo;
