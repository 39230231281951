import React from 'react';
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';

const SectionTwo = () => {
  const containerStyle = {
    backgroundColor: '#d9e7ed',
    padding: '2rem 4rem',
  
    color: '#0D3558',
    fontFamily: 'Arial, sans-serif',

    marginTop: '100px'
  };

  const headerStyle = {
    width:"45vw",
    fontSize: '64px',
    fontWeight: 'bold',
    marginBottom: '3.5rem',
  };

  const locationsContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
  };

  const locationStyle = {
    maxWidth: '45%',
    lineHeight: '1.6',
  };

  const countryStyle = {
    fontSize: '24px',
    fontWeight: 400,
    letterSpacing:"2px",
    marginBottom: '0.5rem',
  };

  const iconTextStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize:"24px",
    fontWeight:400,
    marginBottom: '0.5rem',


  };

  const iconStyle = {
    marginRight: '1rem',
    color: '#0D3558',height:"44px",width:"26px"
  };

  return (
    <div style={containerStyle}>
      <h2 style={headerStyle}>Our Global Locations & Contact Details</h2>
      <div style={locationsContainer}>
        <div style={locationStyle}>
          <div style={countryStyle}>SWEDEN</div>
          <div style={iconTextStyle}>
            <FaPhoneAlt style={iconStyle} />
            +46-708943293
          </div>
          <div style={iconTextStyle}>
            <FaMapMarkerAlt style={iconStyle} />
            Folkvisegatan 2, Gothenburg.
          </div>
        </div>
        <div style={locationStyle}>
          <div style={countryStyle}>INDIA</div>
          <div style={iconTextStyle}>
            <FaPhoneAlt style={iconStyle} />
            (+91) 973 – 875 – 7221
          </div>
          <div style={iconTextStyle}>
            <FaMapMarkerAlt style={iconStyle} />
            No. 152, 2nd Cross, MICO Layout, 2 Stage, Arekere, Bengaluru, Karnataka.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
